.focusedBLK:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px gray;
}
.focusedBLK:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px gray;
}
.focusedGRN:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px rgba(16,82,134,0.9);
}
.focusedGRN:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px rgba(16,82,134,0.9);
}