@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap);
@import url(http://fonts.cdnfonts.com/css/gotham);
.chatDiv {
	border-radius: 10pt;
	position: fixed;
	left: 3vw;
	bottom: 20vh;
	z-index: 100;
	background-color: whitesmoke;
	padding-top: 10pt;
	padding-bottom: 10pt;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border;
	/* font-family: 'Rubik', sans-serif; */
	font-family: 'Gotham-Regular', sans-serif
		/* -ms-overflow-style: none; */
}

/* ::-webkit-scrollbar {
  display: none;
} */

body {
	width: 100%;
	overflow-x: hidden;
}

body.hideScroll {
	overflow: hidden;
}

a {
	text-decoration: none;
}

@-webkit-keyframes animationDown {

	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(5px);
		        transform: translateY(5px);
	}

	60% {
		-webkit-transform: translateY(3px);
		        transform: translateY(3px);
	}
}

@keyframes animationDown {

	0%,
	20%,
	50%,
	80%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(5px);
		        transform: translateY(5px);
	}

	60% {
		-webkit-transform: translateY(3px);
		        transform: translateY(3px);
	}
}
.hoverAbleButton:hover{
    transition: 0.6s;
    box-shadow: 6px 6px 80px gray;
}

.hoverAbleButtonCNO:hover{
    transition: 0.6s;
    box-shadow: 6px 6px 80px gray;
}

.hoverAbleButton:focus{
    transition: 0.6s;
    box-shadow: 1px 1px 50px rgb(21, 161, 255);
}

.hoverAbleButtonGRN:hover{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(21, 161, 255);
}
.hoverAbleButtonGRN:focus{
    transition: 0.6s;
    box-shadow: 1px 1px 70px rgb(21, 161, 255);
}

.hoverAbleButtonGRN:active{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(131, 131, 131);
}

.hoverAbleButtonCNO:focus{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(66, 66, 66);
}

.hoverAbleButton{
    transition: 0.6s;
}

.hoverAbleButtonCNOGRN:hover {
    transition: 0.6s;
    box-shadow: 2px 2px 70px rgba(0, 128, 0, 0.445);
}

.focusedRED:hover {
    transition: 0.6s;
    box-shadow: 2px 2px 70px rgba(255, 0, 0, 0.445);
}
/* rgba(16,82,134,0.9) */
.mainTabAnchor {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
}
.mainTabAnchor:hover {
    background-color: rgba(165, 178, 182, 0.548);
    /* margin-left: 2px; */
    /* margin-right: 2px; */
    padding: 5px;
    border-radius: 7px;
}

.BTNTAB:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.178);
    box-shadow: 3px 3px 50px rgb(116, 116, 116);
}
.BTNTAB:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.178);
    box-shadow: 3px 3px 50px rgb(116, 116, 116);
}
.BTNTAB {
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 1s;
    margin-left: 7px;
    margin-right: 7px;
    
}

.BTNTAB2SIGNOUT:hover {
    transition: 0.7s;
    padding-top: 3%;
    padding-bottom: 3%;
    box-shadow: 2px 2px 50px rgba(252, 0, 0, 0.295);
}
.BTNTAB2SIGNOUT {
    margin-top: 3%;
    margin-bottom: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
    border-radius: 15px;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 0.1s;
}
.BTNTAB2:hover {
    transition: 0.1s;
    color: rgb(83, 83, 83);
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 7%;
    box-shadow: 3px 3px 50px rgba(16,82,134,0.5);
}
.BTNTAB2 {
    margin-top: 3%;
    margin-bottom: 3%;
    padding-top: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
    color: rgb(83, 83, 83);
    border-bottom: 1px solid rgba(16,82,134,0.4);
    border-radius: 15px;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 0.1s;
}

.BTNTAB2AID {
    text-decoration: none;
    padding-left: 2%;
}

.OtherLinks {
    text-decoration: none;
}

.focusedBLK:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px gray;
}
.focusedBLK:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px gray;
}
.focusedGRN:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px green;
}
.focusedGRN:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px green;
}
.focusedYEL:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px rgb(255,197,7);
}
.focusedYEL:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px rgb(255,197,7);
}

.focusedBLK:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px gray;
}
.focusedBLK:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px gray;
}
.focusedGRN:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px rgba(16,82,134,0.9);
}
.focusedGRN:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px rgba(16,82,134,0.9);
}
.popupButton:hover {
    color: white;
    border-color: rgb(5, 73, 124);
    background-color: rgb(5, 73, 124);
}
.popupButton {
    border-color: rgb(5, 73, 124);
    background-color: white;
}

.parent {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.lchild {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68vw;
  padding: 200pt;
  position: fixed;
}

.spacer_lchild {
  width: 68vw;
}

.rchild {
  padding-top: 13vh;
  min-height: 100vh;
  width: 32vw;
  background-color: rgba(255, 255, 255, 0.521);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}


.prdImg {
  width: 60%;
}

.bottomBar {
  min-height: 7vh;
  width: 80%;
  background-color: rgb(233, 233, 233);
  position: absolute;
  margin: 0%;
  bottom: 0%;
  border-top-left-radius: 20pt;
  border-top-right-radius: 20pt;
  box-shadow: 1px 1px 40px rgb(231, 231, 231);
  padding: 1%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.center {
  text-align: center;
  justify-content: center;
  align-self: center;
}

.invoiceModal {
  background-color: white;
  height: 50vh;
  overflow: scroll;
  min-width: 50vh;
  border-radius: 10pt;
  -webkit-transform: scale(0);
          transform: scale(0);
  box-shadow: 0px 0px 30px gray;
}

@media screen and (max-width: 650px) {
  .invoiceModal {
    width: 100vh;
  }
}

@media screen and (max-width: 1370px) {
  .parent {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .lchild {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .spacer_lchild {
    height: 100vh;
  }

  .rchild {
    min-height: 100vh;
    width: 100vw;
    z-index: 1;
  }
}




.configPanel {
  width: 108%;
  padding-top: 55pt;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.670);
  text-align: center;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.rotate {
  transition: 0.5s;
}


.PriceBubble {
  background-color: rgba(110, 110, 110, 0.253);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 1%;
  display: flex;
  flex-direction: row;
}

.PriceBubbleItem {
  transition: 0.2s;
  border-radius: 100px;
  border: 2px solid transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.PriceBubbleItem:hover {
  text-shadow: 1px 1px 30px black;
}

.PriceBubbleItemActive {
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.295);
  border-radius: 100px;
  border: 2px solid transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 1px 1px 20px gray;
  cursor: pointer;
}

.OptionBubbleActive {
  transition: 0.2s;
  background-color: transparent;
  color: gray;
  border: 2px solid rgb(64, 31, 255);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.OptionBubbleActive:hover {
  box-shadow: 1px 1px 30px gray;
}

.OptionBubbleOption {
  color: gray;
  text-align: start;
  padding-left: 10%;
}

.OptionBubblePrice {
  text-align: right;
}

.OptionBubble {
  transition: 0.5s;
  background-color: transparent;
  color: gray;
  border: 2px solid gray;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding-top: 2.5%;
  padding-bottom: 2.5%;

  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.OptionBubble:hover {
  /* border: 2px solid blue; */
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.438);
}

.PaintOption {
  background-color: transparent;
  color: black;
  border: 2px solid gray;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 2.5%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.colorPallet {
  transition: 0.4s;
  cursor: pointer;
  border: 3px solid gray;
}

.colorPallet:hover {
  box-shadow: 2px 2px 50px black;
}

.colorPalletActive {
  transition: 0.5s;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 1px 1px 10px rgb(44, 44, 44);
}

.OrderButton {
  color: white;
  transition: 1s;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 0%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.buyButton {
  transition: 0.2s;
  border-radius: 100px;


  background-color: transparent;
  text-transform: capitalize;
  font-size: large;
  padding: 1%;
}

.buyButton:hover {
  border-radius: 100px;

  color: lightgray;

}

.buyButton:active {
  transition: 0.1s;
  box-shadow: 1px 1px 15px rgba(0, 0, 255, 0.616);
}

.Link {
  transition: 0.2s;
  border-bottom: 3px solid transparent;
  color: white;
}

.Link:hover {
  border-bottom: 3px solid rgb(186, 186, 186);
}

.mobileViewIndicatorDownArrow {
  visibility: hidden;
  position: fixed;
  bottom: 75pt;
  left: 45%;
}

.livePrev {
  height: 100vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1199px) {
  .mobileViewIndicatorDownArrow {
    visibility: visible;
    position: fixed;
    bottom: 75pt;
    left: 45%;
  }

  .livePrevClass {
    top: 3vh;
    margin: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 87vh;
    width: 90%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.livePrevClass {
  top: 3vh;
  margin: 5%;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position:center; */
  height: 87vh;
  width: 90%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.featureButton {
  transition: 0.2s;
  background-color: rgb(233, 233, 233);
  padding-left: 20pt;
  padding-right: 20pt;
  padding-top: 6pt;
  padding-bottom: 6pt;
  border-radius: 50pt;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid transparent;
  color: black;
  font-size: 12pt;
  box-shadow: 1px 1px 10px rgb(185, 185, 185);
}

.featureButton:hover {
  border: 2px solid transparent;
  color: black;
  box-shadow: 1px 1px 20px gray;
}

.featureButton:active {
  border: 2px solid transparent;
  box-shadow: 1px 1px 5px gray;
}

.myCarouselClass {
  position: fixed;
  width: 300vw;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 2201;
}

.floatingModel {
  background-color: white;
  padding: 10pt;
  min-height: 50vh;
  min-width: 50vw;
  margin: 20pt;
  border-radius: 10pt;
  box-shadow: 0px 0px 30px rgb(170,170,170);
}

.floatingModelTransparent {
  background-color: transparent;
  height: 50vh;
  width: 20vw;
  margin: 20pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.focusedGRN:hover {
  transition: 0.5s;
  box-shadow: 3px 3px 30px rgba(16, 82, 134, 0.9);
}

.focusedGRN:focus {
  transition: 0.7s;
  box-shadow: 3px 3px 120px rgba(16, 82, 134, 0.9);
}
