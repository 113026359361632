/* rgba(16,82,134,0.9) */
.mainTabAnchor {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: normal;
}
.mainTabAnchor:hover {
    background-color: rgba(165, 178, 182, 0.548);
    /* margin-left: 2px; */
    /* margin-right: 2px; */
    padding: 5px;
    border-radius: 7px;
}

.BTNTAB:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.178);
    box-shadow: 3px 3px 50px rgb(116, 116, 116);
}
.BTNTAB:hover {
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.178);
    box-shadow: 3px 3px 50px rgb(116, 116, 116);
}
.BTNTAB {
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 1s;
    margin-left: 7px;
    margin-right: 7px;
    
}

.BTNTAB2SIGNOUT:hover {
    transition: 0.7s;
    padding-top: 3%;
    padding-bottom: 3%;
    box-shadow: 2px 2px 50px rgba(252, 0, 0, 0.295);
}
.BTNTAB2SIGNOUT {
    margin-top: 3%;
    margin-bottom: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
    border-radius: 15px;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 0.1s;
}
.BTNTAB2:hover {
    transition: 0.1s;
    color: rgb(83, 83, 83);
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 7%;
    box-shadow: 3px 3px 50px rgba(16,82,134,0.5);
}
.BTNTAB2 {
    margin-top: 3%;
    margin-bottom: 3%;
    padding-top: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
    color: rgb(83, 83, 83);
    border-bottom: 1px solid rgba(16,82,134,0.4);
    border-radius: 15px;
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: 0.1s;
}

.BTNTAB2AID {
    text-decoration: none;
    padding-left: 2%;
}

.OtherLinks {
    text-decoration: none;
}
