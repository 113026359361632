.focusedBLK:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px gray;
}
.focusedBLK:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px gray;
}
.focusedGRN:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px green;
}
.focusedGRN:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px green;
}
.focusedYEL:hover {
    transition: 0.5s;
    box-shadow: 3px 3px 30px rgb(255,197,7);
}
.focusedYEL:focus {
    transition: 0.7s;
    box-shadow: 3px 3px 120px rgb(255,197,7);
}
