.hoverAbleButton:hover{
    transition: 0.6s;
    box-shadow: 6px 6px 80px gray;
}

.hoverAbleButtonCNO:hover{
    transition: 0.6s;
    box-shadow: 6px 6px 80px gray;
}

.hoverAbleButton:focus{
    transition: 0.6s;
    box-shadow: 1px 1px 50px rgb(21, 161, 255);
}

.hoverAbleButtonGRN:hover{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(21, 161, 255);
}
.hoverAbleButtonGRN:focus{
    transition: 0.6s;
    box-shadow: 1px 1px 70px rgb(21, 161, 255);
}

.hoverAbleButtonGRN:active{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(131, 131, 131);
}

.hoverAbleButtonCNO:focus{
    transition: 0.6s;
    box-shadow: 3px 3px 30px rgb(66, 66, 66);
}

.hoverAbleButton{
    transition: 0.6s;
}

.hoverAbleButtonCNOGRN:hover {
    transition: 0.6s;
    box-shadow: 2px 2px 70px rgba(0, 128, 0, 0.445);
}

.focusedRED:hover {
    transition: 0.6s;
    box-shadow: 2px 2px 70px rgba(255, 0, 0, 0.445);
}