.parent {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.lchild {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68vw;
  padding: 200pt;
  position: fixed;
}

.spacer_lchild {
  width: 68vw;
}

.rchild {
  padding-top: 13vh;
  min-height: 100vh;
  width: 32vw;
  background-color: rgba(255, 255, 255, 0.521);
  backdrop-filter: blur(5px);
}


.prdImg {
  width: 60%;
}

.bottomBar {
  min-height: 7vh;
  width: 80%;
  background-color: rgb(233, 233, 233);
  position: absolute;
  margin: 0%;
  bottom: 0%;
  border-top-left-radius: 20pt;
  border-top-right-radius: 20pt;
  box-shadow: 1px 1px 40px rgb(231, 231, 231);
  padding: 1%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.center {
  text-align: center;
  justify-content: center;
  align-self: center;
}

.invoiceModal {
  background-color: white;
  height: 50vh;
  overflow: scroll;
  min-width: 50vh;
  border-radius: 10pt;
  transform: scale(0);
  box-shadow: 0px 0px 30px gray;
}

@media screen and (max-width: 650px) {
  .invoiceModal {
    width: 100vh;
  }
}

@media screen and (max-width: 1370px) {
  .parent {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .lchild {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .spacer_lchild {
    height: 100vh;
  }

  .rchild {
    min-height: 100vh;
    width: 100vw;
    z-index: 1;
  }
}




.configPanel {
  width: 108%;
  padding-top: 55pt;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.670);
  text-align: center;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  transform: translateX(0%);
}

.rotate {
  transition: 0.5s;
}


.PriceBubble {
  background-color: rgba(110, 110, 110, 0.253);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 1%;
  display: flex;
  flex-direction: row;
}

.PriceBubbleItem {
  transition: 0.2s;
  border-radius: 100px;
  border: 2px solid transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.PriceBubbleItem:hover {
  text-shadow: 1px 1px 30px black;
}

.PriceBubbleItemActive {
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.295);
  border-radius: 100px;
  border: 2px solid transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 1px 1px 20px gray;
  cursor: pointer;
}

.OptionBubbleActive {
  transition: 0.2s;
  background-color: transparent;
  color: gray;
  border: 2px solid rgb(64, 31, 255);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.OptionBubbleActive:hover {
  box-shadow: 1px 1px 30px gray;
}

.OptionBubbleOption {
  color: gray;
  text-align: start;
  padding-left: 10%;
}

.OptionBubblePrice {
  text-align: right;
}

.OptionBubble {
  transition: 0.5s;
  background-color: transparent;
  color: gray;
  border: 2px solid gray;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding-top: 2.5%;
  padding-bottom: 2.5%;

  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.OptionBubble:hover {
  /* border: 2px solid blue; */
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.438);
}

.PaintOption {
  background-color: transparent;
  color: black;
  border: 2px solid gray;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 2.5%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.colorPallet {
  transition: 0.4s;
  cursor: pointer;
  border: 3px solid gray;
}

.colorPallet:hover {
  box-shadow: 2px 2px 50px black;
}

.colorPalletActive {
  transition: 0.5s;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 1px 1px 10px rgb(44, 44, 44);
}

.OrderButton {
  color: white;
  transition: 1s;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 100px;
  padding: 0%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.buyButton {
  transition: 0.2s;
  border-radius: 100px;


  background-color: transparent;
  text-transform: capitalize;
  font-size: large;
  padding: 1%;
}

.buyButton:hover {
  border-radius: 100px;

  color: lightgray;

}

.buyButton:active {
  transition: 0.1s;
  box-shadow: 1px 1px 15px rgba(0, 0, 255, 0.616);
}

.Link {
  transition: 0.2s;
  border-bottom: 3px solid transparent;
  color: white;
}

.Link:hover {
  border-bottom: 3px solid rgb(186, 186, 186);
}

.mobileViewIndicatorDownArrow {
  visibility: hidden;
  position: fixed;
  bottom: 75pt;
  left: 45%;
}

.livePrev {
  height: 100vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1199px) {
  .mobileViewIndicatorDownArrow {
    visibility: visible;
    position: fixed;
    bottom: 75pt;
    left: 45%;
  }

  .livePrevClass {
    top: 3vh;
    margin: 5%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 87vh;
    width: 90%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.livePrevClass {
  top: 3vh;
  margin: 5%;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-position:center; */
  height: 87vh;
  width: 90%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.featureButton {
  transition: 0.2s;
  background-color: rgb(233, 233, 233);
  padding-left: 20pt;
  padding-right: 20pt;
  padding-top: 6pt;
  padding-bottom: 6pt;
  border-radius: 50pt;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid transparent;
  color: black;
  font-size: 12pt;
  box-shadow: 1px 1px 10px rgb(185, 185, 185);
}

.featureButton:hover {
  border: 2px solid transparent;
  color: black;
  box-shadow: 1px 1px 20px gray;
}

.featureButton:active {
  border: 2px solid transparent;
  box-shadow: 1px 1px 5px gray;
}

.myCarouselClass {
  position: fixed;
  width: 300vw;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  z-index: 2201;
}

.floatingModel {
  background-color: white;
  padding: 10pt;
  min-height: 50vh;
  min-width: 50vw;
  margin: 20pt;
  border-radius: 10pt;
  box-shadow: 0px 0px 30px rgb(170,170,170);
}

.floatingModelTransparent {
  background-color: transparent;
  height: 50vh;
  width: 20vw;
  margin: 20pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

.focusedGRN:hover {
  transition: 0.5s;
  box-shadow: 3px 3px 30px rgba(16, 82, 134, 0.9);
}

.focusedGRN:focus {
  transition: 0.7s;
  box-shadow: 3px 3px 120px rgba(16, 82, 134, 0.9);
}