@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;900&display=swap');
@import url('http://fonts.cdnfonts.com/css/gotham');

.chatDiv {
	border-radius: 10pt;
	position: fixed;
	left: 3vw;
	bottom: 20vh;
	z-index: 100;
	background-color: whitesmoke;
	padding-top: 10pt;
	padding-bottom: 10pt;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border;
	/* font-family: 'Rubik', sans-serif; */
	font-family: 'Gotham-Regular', sans-serif
		/* -ms-overflow-style: none; */
}

/* ::-webkit-scrollbar {
  display: none;
} */

body {
	width: 100%;
	overflow-x: hidden;
}

body.hideScroll {
	overflow: hidden;
}

a {
	text-decoration: none;
}

@keyframes animationDown {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(5px);
	}

	60% {
		transform: translateY(3px);
	}
}